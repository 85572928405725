<template>
  <div class="home">
    <Section light>
      <h1>Software</h1>
      <v-col cols="12">
        <p>
          We write all the code for the rover, including UI, Vision, Motion
          Planning and Controls. All our code can be viewed on our
          <a href="https://gitlab.com/Yonder-Dynamics/" target="_blank"
            >Gitlab</a
          >. Here's a subsystem-level breakdown of some features which we've
          implemented, those which we're currently working on, and the
          technologies we use:
        </p>
      </v-col>
      <v-col cols="12">
        <h2>Autonomous Navigation</h2>
      </v-col>
      <v-col class="px-4 col-md-6 col-12">
        <v-img
          class="shrink mx-auto"
          :max-height="450"
          contain
          :src="require('@/assets/software/autonomous.jpg')"
        />
      </v-col>
      <v-col class="px-4 col-md-6 col-12">
        <ul>
          <li>
            Navigate moderate terrain to a desired GPS point, within 3 meters
          </li>
          <li>AR tag detection / Object detection</li>
          <ul>
            <li>Computer Vision for AR tag detection</li>
            <li>Custom object detection using CNNs</li>
            <li>Position Filtering for continuous tag location estimation</li>
          </ul>
          <li>
            Navigate to a "post" marked with an AR tag, within 2 meters (in
            progress)
          </li>
          <li>Automatically return to last known post if no AR tag found</li>
          <li>Kalman filtering from many data sources for localization</li>
          <li>Efficient exploration of unknown terrain</li>
        </ul>
        <p>Technologies: ROS, C++, STM32, OctoMap, ESKF, Yolov3, PyTorch</p>
      </v-col>
      <v-col cols="12">
        <h2>UI</h2>
      </v-col>
      <v-col class="px-4 col-md-6 col-12">
        <ul>
          <li>Website based rover control panel</li>
          <li>Plug-and-play Playstation controller support</li>
          <li>Multiple driver support</li>
          <li>Real-time, high quality video feeds</li>
          <li>Science payload control and live data feed</li>
          <li>Battery monitoring and other rover status output</li>
          <li>Rover map with path planning and mapping</li>
        </ul>
        <p>Technologies: React, Websockets</p>
      </v-col>
      <v-col class="px-4 col-md-6 col-12">
        <v-img
          class="shrink mx-auto"
          :max-height="450"
          contain
          :src="require('@/assets/software/ui.png')"
        />
      </v-col>
      <v-col cols="12">
        <h2>Arm</h2>
      </v-col>
      <v-col class="px-4 col-md-6 col-12">
        <v-img
          class="shrink mx-auto"
          :max-height="450"
          contain
          :src="require('@/assets/software/arm.jpg')"
        />
      </v-col>
      <v-col class="px-4 col-md-6 col-12">
        <ul>
          <li>
            Forward Kinematics for gravity correction for joint-by-joint control
          </li>
          <li>Inverse Kinematics with PID for Cartesian arm control</li>
          <li>Autonomous/preprogrammed pose sequences</li>
          <li>Arm Simulation to increase development velocity</li>
        </ul>
        <p>Technologies: ROS, Inverse Kinematics & PID</p>
      </v-col>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'Mechanical',
  components: {
    Section,
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
h2 {
  text-align: center;
}
a {
  color: white;
}
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-top: 20px;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
